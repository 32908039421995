<template>
  <header class="relative z-20">
    <nav class="text-white">
      <div class="relative w-full overflow-x-hidden md:overflow-x-visible z-10">
        <svg
          v-if="!showMegaDropdown && !showSearchBar"
          class="absolute h-[60px] w-[200%] -left-1/2 md:w-full md:left-0"
          fill="none"
          height="60"
          viewBox="0 0 663 60"
          width="663"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="m663 0h-331.25-331.25v60h259.996c10.394 0 18.437-8.8265 22.202-18.5 7.389-18.9833 26.45-32.5 48.8-32.5s41.411 13.5167 48.8 32.5c.108.2782.214.5573.317.8373 3.412 9.2811 11.087 17.6627 20.989 17.6627h261.396z"
            fill="#002044"
          />
        </svg>
        <div class="flex">
          <!-- Left navigation -->
          <div class="flex items-center h-[60px] w-1/12 md:w-5/12 bg-content-dark-blue z-20">
            <NavbarBrand
              portal-title="pecan"
              class="ml-[20px]"
            />

            <!-- Nav links on larger screens -->
            <div class="lg:ml-[32px] hidden xl:flex">
              <router-link
                v-for="menuItem in menuItems"
                :key="menuItem.title"
                :to="menuItem.to"
                class="px-[10px] py-[8px] text-sm text-white hover:bg-blue-dark rounded-md"
                :class="[{'bg-content-dark-blue opacity-60': menuItem.isActive()}]"
              >
                {{ menuItem.title }}
              </router-link>
              <a
                href="https://university.stjude.cloud/docs/pecan/"
                target="_blank"
                class="px-[10px] py-[8px] mx-[5px] text-sm text-white hover:bg-blue-dark rounded-md"
              >
                <span class="text-ellipsis overflow-hidden">Documentation</span>
              </a>
            </div>
          </div>

          <!-- Membrane button -->
          <div
            id="membrane"
            class="relative h-[60px] flex w-10/12 md:w-1/6 min-w-[148px] pb-32 md:pb-0"
            :class="showMegaDropdown || showSearchBar ? 'bg-content-dark-blue pb-0' : 'bg-transparent'"
          >
            <div
              v-show="!showMegaDropdown && ! showSearchBar"
              class="w-full"
            >
              <div
                class="relative mx-auto mt-[20px]  bg-white sm:mt-[16px] w-20 h-[78px] sm:h-[86px] sm:w-[86px] rounded-full md:top-0"
              >
                <span class="absolute bg-[#ececec] h-full w-full rounded-full opacity-80 ring-[#ececec] ring" />
                <span class="absolute bg-[#ececec] h-full w-full rounded-full opacity-80 ring-[#ececec] ring" />
                <span class="absolute bg-[#ececec] h-full w-full rounded-full opacity-80 ring-[#ececec] ring" />
              </div>
              <img
                ref="lightMembraneToggleBtnEl"
                src="/images/navbar/membrane-btn.svg"
                class="absolute top-[20px] sm:top-4 left-1/2 transform -translate-x-1/2 cursor-pointer grayscale focus:outline z-10 membrane-btn opacity-10 w-20 sm:w-max"
                @click="toggleMembraneOverlay()"
              >
              <div class="membrane-btn-dark opacity-0">
                <img
                  src="/images/navbar/membrane-dark.svg"
                  class="absolute top-0 left-1/2 transform -translate-x-1/2 cursor-pointer focus:outline-0 z-10"
                  @click="toggleMembraneOverlay()"
                >
                <MembraneOverlay
                  :visible="membraneOverlayVisible"
                  @ready="handleMembraneReady()"
                  @data-facet-btn-click="toggleMembraneOverlay()"
                  @membrane-hidden="emit('click:sunburst', false)"
                  @membrane-visible="emit('click:sunburst', true)"
                />
              </div>
            </div>
          </div>

          <!-- Right navigation -->
          <div class="flex justify-end items-center h-[60px] w-1/12 md:w-5/12 bg-content-dark-blue z-10">
            <!-- Auth button -->
            <AuthButton class="lg:order-2 hidden xs:block" />

            <!-- Search bar toggle button for smaller screens -->
            <button
              class="px-[10px] py-[12px]  rounded-md lg:hidden flex"
              :class="showSearchBar ? 'bg-blue-500-alt hover:bg-blue-500-alt' : 'hover:bg-blue-dark'"
              @click="toggleSearchBar"
            >
              <font-awesome-icon
                :icon="['fas', 'search']"
                class="w-5"
              />
            </button>

            <!-- Search bar for larger screens -->
            <OmniSearch
              class="hidden lg:block lg:order-1"
              @select-result="openMembraneOverlay"
            />

            <!-- Mega menu toggle button -->
            <button
              ref="megaMenuButton"
              class="order-3 p-[10px] mr-[20px] hover:bg-blue-dark rounded-md flex"
              @click="toggleMegaMenu"
            >
              <font-awesome-icon
                :icon="['fas', showMegaDropdown ? 'xmark' : 'bars']"
                class="text-xl w-5"
              />
            </button>
          </div>
        </div>
      </div>
    </nav>
    
    <!-- Search bar for smaller screens -->
    <div
      class="lg:hidden absolute w-full h-full bg-content-dark-blue border-t border-blue-500-alt text-white origin-top transition-transform duration-300 top-[60px]"
      :class="showSearchBar ? 'scale-y-100':'scale-y-0'"
    >
      <OmniSearch @select-result="openMembraneOverlay" />
    </div>

    <!-- Mega menu -->
    <div
      ref="megaMenu"
      v-click-outside="handleClickOutsideMegaMenu"
      class="absolute w-full origin-top transition-transform duration-300 top-[60px]"
      :class="showMegaDropdown ? 'scale-y-100' : 'scale-y-0'"
    >
      <PecanMegaMenu>
        <!-- Collapsed nav links -->
        <div
          class="xl:hidden h-[60px] items-center border-y border-blue-500-alt bg-content-dark-blue grid grid-cols-2"
        >
          <router-link
            v-for="menuItem in menuItems"
            :key="menuItem.title"
            :to="menuItem.to"
            class="text-white hover:bg-blue-dark flex justify-center border-r border-blue-500-alt items-center h-full active:bg-blue-500-alt"
            :class="[{'bg-blue-500-alt hover:bg-blue-500-atl': menuItem.isActive()}]"
            @click="toggleMegaMenu"
          >
            {{ menuItem.title }}
          </router-link>
          <a
            href="https://university.stjude.cloud/docs/pecan/"
            target="_blank"
            class="text-white hover:bg-blue-dark flex justify-center items-center h-full active:bg-blue-500-alt"
          >
            <span class="text-ellipsis overflow-hidden">Documentation</span>
          </a>
        </div>
      </PecanMegaMenu>
    </div>
  </header>
</template>

<script setup>
import gsap from "gsap";
import { storeToRefs } from "pinia";
import { onMounted, ref, watch } from "vue";
import { useRoute } from "vue-router";

import { useSunburstStore } from '@/store/sunburst';
import OmniSearch from "@/components/OmniSearch";
import PecanMegaMenu from "@/layouts/PecanMegaMenu";

import NavbarBrand from "@/layouts/navbar/NavbarBrand";
import AuthButton from "@/components/auth/AuthButton";
import MembraneOverlay from '@/layouts/navbar/MembraneOverlay';

const route = useRoute();
const sunburstStore = useSunburstStore();
const { isMembraneOpen } = storeToRefs(sunburstStore);

const showSearchBar = ref(false);
const showMegaDropdown = ref(false);
const lightMembraneToggleBtnEl = ref(null);
const membraneOverlayReady = ref(false);
const membraneOverlayVisible = ref(false);
const menuItems = ref([
  {
    title: 'PIE',
    to: '/pie',
    isActive: () => route.path.startsWith('/pie')
  },
]);
const megaMenuButton = ref(null);
const megaMenu = ref(null);
const emit = defineEmits(['click:mega-menu', 'click:omnisearch', 'click:sunburst']);

/**
 * Lifecycle
 * ------------------------------------------------------
 */

onMounted(() => {
  // Pulsate rings around the toggle-membrane button
  gsap.to('#membrane .ring', {
    scale: 1.75,
    opacity: 0,
    duration: 2,
    stagger: {
      each: 0.5,
      repeat: -1,
    },
  });
});

/**
 * Events
 * ------------------------------------------------------
 */

// Switch between light & dark navbars when the membrane overlay is toggled
watch(
  () => membraneOverlayVisible.value, (visible) => {
    if (visible) {
      isMembraneOpen.value = true;
      gsap.to('#membrane .membrane-btn', { opacity: 0 });
      gsap.to('#membrane .membrane-btn-dark', { opacity: 1 });
    } else {
      gsap.to('#membrane .membrane-btn', { opacity: 1 });
      gsap.to('#membrane .membrane-btn-dark', { opacity: 0 }).eventCallback("onComplete", () => { isMembraneOpen.value = false });
    }
  },
);

// Close membrane overlay when navigating/route changes
watch(() => route.fullPath, () => {
  if (membraneOverlayVisible.value) {
    toggleMembraneOverlay();
  }
});

/**
 * Other events
 * ------------------------------------------------------
 */

 // Close dropdown when clicking outside
const handleClickOutsideMegaMenu = (event) => {
  if (
    megaMenu.value &&
    megaMenuButton.value &&
    !megaMenuButton.value.contains(event.target) &&
    !megaMenu.value.contains(event.target)
  ) {
    emit('click:mega-menu', false);
    showMegaDropdown.value = false;
  }
};

const handleMembraneReady = () => {
  // Remove pulsating rings and fade in the toggle-membrane button
  lightMembraneToggleBtnEl.value.classList.remove('grayscale');
  gsap.to(lightMembraneToggleBtnEl.value, {
    opacity: 1,
    duration: 0.5,
    onComplete() {
      gsap.to('#membrane .ring', {
        opacity: 0,
        duration: 0.5,
        onComplete() {
          gsap.killTweensOf('#membrane .ring');
          membraneOverlayReady.value = true;
        },
      });
    },
  });
}

const toggleMembraneOverlay = () => {
  if (membraneOverlayReady.value) {
    membraneOverlayVisible.value = !membraneOverlayVisible.value;
  }
}

function openMembraneOverlay() {
  if(!membraneOverlayVisible.value)
    membraneOverlayVisible.value = true;
}

const toggleMegaMenu = () => {
  showMegaDropdown.value = !showMegaDropdown.value;
  emit('click:mega-menu', showMegaDropdown.value);
  if (showSearchBar.value) {
    showSearchBar.value = false;
    emit('click:omnisearch', false);
  }
}

const toggleSearchBar = () => {
  showSearchBar.value = !showSearchBar.value;
  emit('click:omnisearch', showSearchBar.value);
  if (showMegaDropdown.value) {
    showMegaDropdown.value = false;
    emit('click:mega-menu', false);
  }
}

</script>

<style scoped>
.membrane-btn {
  border-radius: 100%;
  box-shadow: 0px 2px 10px #1874DC, 0px 2px 10px #35A7D3, 0px 0px 10px #FFFFFF;
  transition: box-shadow ease-in-out .3s;
}

.membrane-btn:hover {
  box-shadow: 0px -2px 10px #1874DC, 0px 2px 10px #35A7D3, 0px 3px 10px #FFFFFF;
}

.membrane-btn-dark::after {
  bottom: 0;
  content: '';
  box-shadow: 0px -2px 10px #1874DC, 0px -2px 10px #35A7D3, 0px 3px 10px #FFFFFF;
  border-radius: 100%;
  height: 86px;
  left: 0;
  margin-left: auto;
  margin-right: auto;
  opacity: 0;
  position: absolute;
  right: 0;
  top: 16px;
  width: 86px;
  z-index: -1;
  transition: opacity ease-in-out .3s;
}

.membrane-btn-dark:hover::after {
  opacity: 1;
}
</style>
