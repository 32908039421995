<template>
  <div
    v-if="hasLayout"
    class="flex-column flex h-full"
  >
    <!-- Omnibar -->
    <PecanOmniBar
      v-if="useNewOmnibar"
      @click:mega-menu="toggleMegaMenu"
      @click:omnisearch="toggleOmnisearch"
      @click:sunburst="toggleSunburst"
    />
    <OmniBar v-else />

    <!-- Main Content -->
    <main
      id="main"
      :class="[
        'flex-column relative flex flex-1', 
        { 'overflow-hidden': membraneOverlayVisible },
        { 'overflow-hidden md:!overflow-visible': showMegaDropdown && !membraneOverlayVisible },
      ]"
    >
      <NavBar
        v-if="!useNewOmnibar"
        @membrane-hidden="membraneOverlayVisible = false"
        @membrane-visible="membraneOverlayVisible = true"
      />
      <div class="min-h-[800px] h-full">
        <router-view :key="$route.fullPath" />
      </div>
    </main>

    <!-- Footer -->
    <PecanFooter
      v-if="useNewOmnibar"
    />
    <AppFooter
      v-else
    />
  </div>

  <router-view
    v-else
    :key="$route.fullPath"
  />
  <IntercomButton />
</template>

<script setup>
import _ from 'lodash';
import { ref } from 'vue';
import { useRoute } from 'vue-router';

import { FeatureFlags, isFeatureEnabled } from '@/lib/FeatureFlags';
import AppFooter from '@/components/AppFooter';
import IntercomButton from '@/components/IntercomButton';
import NavBar from '@/layouts/NavBar';
import OmniBar from '@/layouts/OmniBar.vue';
import PecanOmniBar from '@/layouts/PecanOmniBar';
import PecanFooter from '@/layouts/PecanFooter';

const route = useRoute();
const hasLayout = ref(false !== _.get(route, 'matched[0].meta.layout', null));
const useNewOmnibar = ref(isFeatureEnabled(FeatureFlags.REDUCED_TOOLBAR));
const membraneOverlayVisible = ref(false);
const showMegaDropdown = ref(false);
const showOmniSearch = ref(false);

const toggleMegaMenu = (state) => {
  showMegaDropdown.value = state;
}
const toggleOmnisearch = (state) => {
  showOmniSearch.value = state;
}
const toggleSunburst = (state) => {
  membraneOverlayVisible.value = state;
}

</script>

<style>
@import '~@stjudecloud/theme/dist/css/stjude-cloud-theme.min.css';

.splash {
  background: url("./assets/app/splash.svg") no-repeat scroll 50% -100px;
  background-size: cover;
}
</style>
