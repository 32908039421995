<template>
  <button
    v-if="!isAuthenticated"
    class="text-sm text-nowrap relative text-white px-[12px] py-[8px] hover:bg-blue-dark rounded-md font-bold disabled:opacity-50 disabled:bg-blue-dark sm:text-base focus:ring"
    :disabled="isLoading"
    @click="handleLogin"
  >
    {{ isLoading ? 'Loading...' : 'Sign In' }}

    <span
      v-if="isLoading"
      class="flex absolute h-3 w-3 top-0 right-0 -mt-1 -mr-1"
    >
      <span class="animate-ping absolute inline-flex h-full w-full rounded-full bg-sky-400 opacity-75" />
      <span class="relative inline-flex rounded-full h-3 w-3 bg-sky-700" />
    </span>
  </button>
  <button
    v-else
    class="text-sm text-nowrap relative text-white px-[12px] py-[8px] hover:bg-blue-dark rounded-md font-bold sm:text-base focus:ring"
    @click="handleLogout"
  >
    Sign Out
  </button>
</template>

<script setup>
import { onMounted } from 'vue';
import { useAuth0 } from '@auth0/auth0-vue';

const { isLoading, loginWithRedirect, logout, isAuthenticated, checkSession } = useAuth0();

const handleLogin = async () => {
  await loginWithRedirect({
    prompt: "login",
    appState: { target: window.location.pathname },
  });
};

const handleLogout = async () => {
  await logout({ logoutParams: { returnTo: window.location.origin } });
}

onMounted(async () => await checkSession());
</script>
