<template>
  <!-- The extra div here is needed because Teleport doesn't act like a root node and we need
  that root node to prevent the Vue "Extraneous non-props ... could not be inherited" warning. -->
  <div>
    <Teleport to="body">
      <div
        id="membraneOverlay"
        class="absolute z-[6] top-0 left-0 w-full flex flex-column align-items-center justify-content-start pt-[125px] bg-membrane-gradient select-none overflow-x-hidden"
      >
        <SelectionInfo
          class="absolute top-[290px] md:top-[150px] px-[8px] md:px-0 right-0 md:right-[24px] w-full md:w-[290px] flex flex-column space-y-[10px] md:space-y-[19px]"
          :disease="sunburstSelection.disease"
          :samples="sunburstSelection.samples"
          :patients="sunburstSelection.patients"
        />

        <div class="absolute top-[400px] md:top-0 left-1/2 transform -translate-x-1/2 w-[770px] mt-20 mx-auto">
          <div
            class="data-facets-circle relative"
            :style="`--m: ${m}; --tan: ${+tan.toFixed(2)}`"
          >
            <SunburstChart @ready="handleSunburstChartReady()" />

            <DataFacetBtn
              v-for="(facet, index) in facets"
              :key="`facet-${index}`"
              :facet="facet"
              :index="index"
              :has-middle="hasMiddle"
              @click="emit('data-facet-btn-click', facet)"
            />
          </div>
        </div>
        <SelectionInfoDiagnosis
          class="hidden md:block absolute top-[290px] md:top-[150px] px-[8px] md:px-[15px] left-0 md:left-[24px] md:w-[290px] space-y-[10px] md:space-y-[20px]"
        />
      </div>
    </Teleport>
  </div>
</template>

<script setup>
import { ref, watch } from 'vue';
import { gsap } from 'gsap';
import DataFacetBtn from '@/layouts/navbar/DataFacetBtn';
import SelectionInfo from '@/layouts/navbar/SelectionInfo';
import { useSunburstStore } from '@/store/sunburst';
import { storeToRefs } from 'pinia';
import SunburstChart from '@/layouts/navbar/SunburstChart';
import SelectionInfoDiagnosis from '@/layouts/navbar/SelectionInfoDiagnosis';

const emit = defineEmits(['ready', 'data-facet-btn-click', 'membrane-visible', 'membrane-hidden']);

const props = defineProps({
  visible: {
    type: Boolean,
    required: true,
  },
});

const sunburstStore = useSunburstStore();

const { sunburstData, sunburstSelection } = storeToRefs(sunburstStore);

//
// Events
//------------------------------------------------------------------------------

watch(
  () => props.visible,
  (visible) => {
    if (visible) {
      gsap.to('#membraneOverlay', {
        clipPath: 'circle(100% at 50% 10%)',
        onComplete() {
          emit('membrane-visible');
        },
      });
    } else {
      gsap.to('#membraneOverlay', {
        clipPath: 'circle(0% at 50% 10%)',
        onStart() {
          emit('membrane-hidden');
        },
      });
    }
  },
);

function handleSunburstChartReady() {
  emit('ready');
}

//
// Data facets
//------------------------------------------------------------------------------

const facets = ref([
  {
    src: 'Epigenetic',
    to: () => '#',
    title: 'Epigenetic',
    comingSoon: true,
    sampleCount: () => 0,
  },
  {
    src: 'Histology',
    to: () => {
      const name = 'histology-home';
      const params = {};

      const { diseaseHierarchy } = sunburstSelection.value;
      if (diseaseHierarchy.length) {
        params.diseaseHierarchy = diseaseHierarchy;
      }

      return {
        name,
        params,
      };
    },
    title: 'Histology',
    sampleCount: () => sunburstSelection.value.histSamples || 0,
  },
  {
    src: 'Expression',
    to: () => {
      const name = 'expression-home';
      const params = { selectedTab: 'tsne' };
      const query = {};

      const { diseaseHierarchy } = sunburstSelection.value;
      if (diseaseHierarchy.length) {
        const diseases = diseaseHierarchy.split('|');
        params.subtypeRoot = diseases[0];
        // Add deepest subtype and any descendant subtypes of it to the URL query
        if(diseases.length > 1) {
          const queriedDiseases = diseases.slice(-1);
          const queriedDiseaseSubtypes = sunburstData.value[diseaseHierarchy]?.disease_descendant_codes;
          if(queriedDiseaseSubtypes) {
            queriedDiseases.push(...queriedDiseaseSubtypes.split(';'));
          }
          query.diseases = queriedDiseases.toString();
        }
      }

      return {
        name,
        params,
        query,
      };
    },
    title: 'Expression',
    sampleCount: () => sunburstSelection.value.tsneSamples || 0,
  },
  {
    src: 'MutationalSignatures',
    to: () => {
      const params = {};
      const { diseaseHierarchy } = sunburstSelection.value;

      if (diseaseHierarchy.length) {
        params.diseaseHierarchy = diseaseHierarchy;
      }

      return {
        name: 'mutational_signature_home',
        params,
      };
    },
    title: 'Mutational Signatures',
    sampleCount: () => sunburstSelection.value.mutsigSamples || 0,
  },
  {
    src: 'Variants',
    to: () => {
      let name = 'variants';
      const params = { selectedTab: 'oncoprint' };
      const { diseaseHierarchy } = sunburstSelection.value;

      if (diseaseHierarchy.length) {
        params.diseaseHierarchy = sunburstSelection.value.diseaseHierarchy;
      }

      return {
        name,
        params,
      };
    },
    title: 'Variants',
    sampleCount: () => sunburstSelection.value.variantsSamples || 0,
  },

  // Placeholders to fill in the data facets circle
  {},
  {},
  {},
]);

let numberOfFacets = facets.value.length;
let hasMiddle = 0; /* 0 if there's no item in the middle, 1 otherwise */
let m = ref(numberOfFacets - hasMiddle); /* how many are ON the circle */
let tan = ref(Math.tan(Math.PI / m.value)); /* tangent of half the base angle */
</script>

<style>
#membraneOverlay {
  clip-path: circle(0% at 50% 10%);
  height: 100vh;
  min-height: 1035px;
}

.data-facets-circle {
  --d: 6.5em;
  /* image size */
  --rel: 1;
  /* how much extra space we want between images, 1 = one image size */
  --r: calc(.5 * (1.61803399 + var(--rel)) * var(--d) / var(--tan));
  /* circle radius */
  --s: calc(2 * var(--r) + var(--d));
  /* container size */
  position: relative;
  width: var(--s);
  height: var(--s);
}

.data-facets-circle button {
  position: absolute;
  top: 50%;
  left: 50%;
  margin: calc(-.5 * var(--d));
  width: var(--d);
  height: var(--d);
  --az: calc(var(--i) * 1turn / var(--m));
  transform: rotate(var(--az)) translate(var(--r)) rotate(calc(-1 * var(--az)));
}

.data-facets-circle button img {
  max-width: 100%;
}
</style>
