<template>
  <div class="h-full xl:mr-[16px]">
    <div class="h-full text-sm flex">
      <div class="h-full hover:bg-blue-dark lg:border-l lg:border-l-blue-500-alt border-r border-r-blue-500-alt cursor-pointer group">
        <Listbox
          v-slot="{ open }"
          :model-value="selectedTab"
          @update:model-value="switchTabTo"
        >
          <ListboxButton
            class="flex items-center justify-between px-[24px] h-full w-32"
            :class="[{'bg-blue-500-alt': open}]"
          >
            <span class="mr-[3px]">
              {{ selectedTab.name }}
            </span>
            <font-awesome-icon
              :icon="['fas', open ? 'caret-up': 'caret-down']"
              class="h-3 w-3"
            />
          </ListboxButton>

          <ListboxOptions class="absolute w-[100%] xs:w-52 p-[8px] rounded-b-lg bg-white text-neutral-700 text-sm z-10 shadow-lg">
            <ListboxOption
              v-for="option in tabs"
              v-slot="{ selected }"
              :key="option.id"
              :value="option"
              class="p-[8px]"
              as="template"
            >
              <li
                class="p-[8px] my-[2px] rounded cursor-pointer"
                :class="[{'bg-blue-primary text-white': selected}, {
                  'hover:bg-blue-100': option.id !== selectedTab.id
                }]"
              >
                {{ option.name }}
              </li>
            </ListboxOption>
          </ListboxOptions>
        </Listbox>
      </div>
      <div class="h-full w-full flex items-center border-r border-r-blue-500-alt">
        <input
          type="text"
          placeholder="Search"
          class="h-full w-full px-[16px] bg-blue-700 hover:bg-blue-dark focus:bg-blue-500-alt focus:outline-none peer"
          :value="searchTerm"
          :readonly="loading"
          @input="debouncedSearch"
          @focus="inFocus = true"
          @blur="inFocus = false"
          @keyup.enter="search($event.target.value)"
        >
        <div
          v-if="loading"
          class="h-full flex peer-hover:bg-blue-dark peer-focus:bg-blue-500-alt pr-[16px]"
        >
          <svg
            class="animate-spin w-[16px]"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
          >
            <circle
              class="opacity-25"
              cx="12"
              cy="12"
              r="10"
              stroke="currentColor"
              stroke-width="4"
            />
            <path
              class="opacity-75"
              fill="currentColor"
              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
            />
          </svg>
        </div>

        <div
          v-else
          class="h-full flex items-center peer-hover:bg-blue-dark peer-focus:bg-blue-500-alt"
        >
          <button
            v-if="searchTerm"
            class="pr-[16px]"
            @click="resetSearch"
          >
            <font-awesome-icon
              icon="times"
            />
          </button>
          <font-awesome-icon
            v-else
            icon="search"
            class="pr-[16px]"
          />
        </div>
      </div>
    </div>
    <div
      class="w-full z-10 relative"
    >
      <ul
        v-show="isOpen && results.length > 0"
        class="bg-white rounded-b-lg border border-neutral-300 text-neutral-700"
      >
        <li
          v-for="(result, index) in results"
          :key="index"
          class="px-[12px] py-[8px] cursor-pointer last:rounded-b-lg border-b hover:bg-neutral-200 hover:text-blue-primary"
          @click="goToResult(result)"
        >
          <div
            v-if="result.type === 'variant'"
            class="flex justify-between text-[14px]"
          >
            <div class="my-auto">
              {{ result.gene_symbol }}
              <span class="font-bold">{{ result.aachange }}</span>
              <span
                v-if="result.duplicateIndex > 1"
                class="text-neutral-400 ml-1 font-bold"
              >
                ({{ result.duplicateIndex }})
              </span>
            </div>
            <div class="text-neutral-600 text-[10px] tracking-widest font-bold flex items-center">
              <div
                v-if="result.class"
                class="bg-neutral-100 flex py-[5px] rounded"
              >
                <span
                  class="w-[10px] h-[10px] mx-1 my-auto rounded block"
                  :class="variantClassBackgrounds[result.class.toLowerCase()]"
                />
                <span class="mr-1 uppercase">{{ result.class }}</span>
              </div>
            </div>
          </div>
          <div
            v-else-if="result.type === 'gene'"
            class="flex justify-between"
          >
            <div>
              {{ result.legacy_text }}
            </div>
            <div
              class="flex text-[9px] font-bold tracking-widest text-[#0A496B] uppercase my-auto border-[1px] border-neutral-300 rounded p-[8px]"
            >
              <img
                :srcset="`/images/variants/ProteinPaint.png 1x, /images/variants/ProteinPaint@2x.png 2x`"
                :src="`/images/variants/ProteinPaint.png`"
                class="w-[16px] h-[12px] mr-[6px]"
              >
              View in ProteinPaint
            </div>
          </div>
          <div
            v-else
            class="flex"
          >
            <div class="w-[200px] text-[12px] my-auto">
              {{ result.dx_full }}
            </div>
            <div
              class="flex items-center justify-center text-[12px] font-bold w-[30px] h-[24px] m-auto border-[1px] rounded-md"
              :class="getTumorColor(result.ancestor_dx)"
            >
              {{ tumorType(result.ancestor_dx) }}
            </div>
            <div class="my-auto ml-[15px] mr-[30px]">
              <div class="text-[9px] text-neutral-500 tracking-widest uppercase font-bold">
                Samples
              </div>
              <div class="text-[12px] text-neutral-700">
                {{ result.total_sample_count }}
              </div>
            </div>
            <div class="my-auto">
              <div class="text-[9px] text-neutral-500 tracking-widest uppercase font-bold">
                Patients
              </div>
              <div class="text-[12px] text-neutral-700">
                {{ result.total_subject_count }}
              </div>
            </div>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script setup>
import _ from 'lodash';
import { useRouter } from 'vue-router';
import { onBeforeUnmount, onMounted, ref } from 'vue';
import { Listbox, ListboxButton, ListboxOptions, ListboxOption } from '@headlessui/vue';
import { useSunburstStore } from '@/store/sunburst';
import { useVariantsStore } from '@/store/variants';
import { useAPI } from '@/api/api';

const tabs = [
  {id: 'diagnosis', name: 'Diagnosis'},
  {id: 'gene', name: 'Gene'},
  {id: 'variant', name: 'Variant'},
];

const variantClassBackgrounds = {
  missense: 'bg-[#3987CC]',
  frameshift: 'bg-[#DB3D3D]',
  nonsense: 'bg-[#FF7F0E]',
  proteindel: 'bg-[#7F7F7F]',
  proteinins: 'bg-[#8C564B]',
  splice: 'bg-[#6633FF]',
  splice_region: 'bg-[#9467BD]',
  silent: 'bg-[#519D3E]',
  utr_3: 'bg-[#998199]',
  utr_5: 'bg-[#819981]',
  intron: 'bg-[#656565]',
  exon: 'bg-black',
};

const sunburstStore = useSunburstStore();
const variantsStore = useVariantsStore();
const router = useRouter();

const selectedTab = ref(tabs[0]);
const inFocus = ref(false);
const isOpen = ref(false);
const searchTerm = ref('');
const results = ref([]);
const loading = ref(false);

const emit = defineEmits(['select-result']);

onMounted(() => {
  document.addEventListener('click', handleClickOutside);
});

onBeforeUnmount(() => {
  document.removeEventListener('click', handleClickOutside);
});

const handleClickOutside = () => {
  isOpen.value = false;
};

const switchTabTo = (newTab) => {
  selectedTab.value = newTab
  resetSearch();
};

const resetSearch = () => {
  searchTerm.value = '';
  results.value = [];
  isOpen.value = false;
}

const search = (searchInput) => {
  isOpen.value = true;
  if (searchInput) {
    searchTerm.value = searchInput;
    loading.value = true;
    if(selectedTab.value.id === 'diagnosis') {
      const searchResults = Object.values(sunburstStore.sunburstData).filter(obj => obj.dx_full.toLowerCase().includes(searchInput.toLowerCase()));
      results.value = searchResults.slice(0, 10);
      loading.value = false;
    } else {
      useAPI()
        .get(`/search/${selectedTab.value.id}/${searchTerm.value}`)
        .then((resp) => resp.data)
        .then((resp) => {
          results.value = resp.data.items;

          const duplicateTexts = {};
          results.value.forEach((result) => {
            duplicateTexts[result.legacy_text] ? (duplicateTexts[result.legacy_text] += 1) : (duplicateTexts[result.legacy_text] = 1);
            result.duplicateIndex = duplicateTexts[result.legacy_text];
          });
        })
        .finally(() => {
          loading.value = false;
        })
        .catch((err) => {
          console.error(`Error requesting /search/${tab.value}/${searchTerm.value}`, {}, err);
        });
    }
  } else {
    resetSearch();
  }
};

const goToResult = (result) => {
  if (selectedTab.value.id === 'gene') {
    // Gene centric user flow - clear any variant existing in the store
    variantsStore.clearVariant();

    if (result) router.push({ path: '/variants/proteinpaint', query: { gene: result.id } });
    else if (searchTerm.value) router.push({ path: '/variants/proteinpaint', query: { gene: props.searchTerm } });
  } else if (selectedTab.value.id === 'variant' && result?.id) {
    router.push({ name: 'variant_page', params: { selectedTab: 'details', variantId: result.id } });
  } else if (selectedTab.value.id === 'diagnosis') {
    for (const title of document.querySelectorAll('title')) {
      if (title.textContent === result.dx_full) {
        var evt = new MouseEvent('click');
        title.parentNode.dispatchEvent(evt);
        emit('select-result');
        return;
      }
    }
  }
  resetSearch();
};

const tumorType = (dxPath) => {
  return dxPath.split('|')[0];
};

const getTumorColor = (dxPath) => {
  switch (tumorType(dxPath)) {
    case 'BT':
      return 'text-sunburst-bt border-sunburst-bt';
    case 'GCT':
      return 'text-sunburst-gct border-sunburst-gct';
    case 'HM':
      return 'text-sunburst-hm border-sunburst-hm';
    case 'ST':
      return 'text-sunburst-st border-sunburst-st';
  }
};

const debouncedSearch = _.debounce((e) => search(e.target.value), 1000);

</script>
