<template>
  <footer class="bg-blue-dark text-white pt-8">
    <div class="container mx-auto">
      <div class="flex flex-col md:flex-row items-start">
        <!-- Logo Section -->
        <div class="flex items-center mb-8 lg:mb-0 mx-[auto] md:mr-16 md:ml-0">
          <a
            href="https://stjude.cloud"
            class="inline-block pr-2 border-r min-w-16"
          >
            <img
              src="/images/stjude-logo-child.svg"
              width="55"
              alt="St. Jude Cloud Logo"
            >
          </a>
          <a
            href="https://stjude.cloud"
            class="text-xl font-extrabold hover:no-underline hover:text-white pl-3 text-nowrap"
          >
            St. Jude Cloud
          </a>
        </div>

        <div class="flex flex-col items-center xs:flex-row xs:items-start justify-between w-full md:w-1/2 text-center md:text-left">
          <!-- About Section -->
          <div class="mb-8 lg:mb-0 w-1/2">
            <h6 class="text-xs font-semibold uppercase tracking-wide mb-2 text-blue-200">
              About
            </h6>
            <ul>
              <li
                v-for="link in aboutLinks"
                :key="link.title"
              >
                <a
                  :href="link.url"
                  :title="link.title"
                  class="hover:underline"
                >{{ link.text }}</a>
              </li>
            </ul>
          </div>

          <!-- Apps Section -->
          <div class="mb-8 lg:mb-0 w-1/2">
            <h6 class="text-xs font-semibold uppercase tracking-wide mb-2 text-blue-200">
              Apps
            </h6>
            <ul>
              <li
                v-for="app in apps"
                :key="app.title"
              >
                <a
                  :href="app.url"
                  :title="app.title"
                  class="hover:underline"
                >{{ app.title }}</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>

    <div class="bg-content-dark-blue text-blue-200 pt-2 pb-1 mt-8">
      <div class="container mx-auto">
        <div class="flex flex-col xl:flex-row items-center justify-between text-xs text-center">
          <!-- Legal Links -->
          <ul class="flex flex-col lg:flex-row xl:order-2">
            <li
              v-for="(link, index) in links"
              :key="link.title"
            >
              <a
                :href="link.url"
                :title="link.title"
                target="_blank"
                rel="noopener noreferrer"
                class="hover:underline text-blue-200"
              >{{ link.text }}</a>
              <span
                v-if="index !== links.length - 1"
                class="px-1 hidden lg:inline-block"
              >&bull;</span>
            </li>
          </ul>

          <!-- Copyright -->
          <p class="xl:order-1 mx-3">
            &copy; Copyright {{ new Date().getFullYear() }}
            <a
              href="https://www.stjude.org"
              title="St. Jude Children's Research Hospital"
              target="_blank"
              class="hover:underline hover:text-blue-200"
            >St. Jude Children's Research Hospital</a>, a not-for-profit, section 501(c)(3).
          </p>
        </div>
      </div>
    </div>
  </footer>
</template>

<script setup>
const aboutLinks = [
  {
    url: 'https://stjude.cloud',
    text: 'Home',
    title: 'St. Jude Cloud Home',
  },
  {
    url: 'https://talent.stjude.org/data-sciences/jobs',
    text: 'Careers',
    title: 'Careers',
  },
  {
    url: 'https://stjude.cloud/privacy-policy',
    text: 'Privacy Policy',
    title: 'Privacy Policy',
  },
  {
    url: 'https://stjude.cloud/terms-of-use',
    text: 'Terms of Use',
    title: 'Terms of Use',
  },
];

const apps = [
  {
    url: 'https://platform.stjude.cloud',
    title: 'Genomics Platform',
  },
  {
    url: 'https://models.stjude.cloud',
    title: 'Model Systems',
  },
  {
    url: 'https://pecan.stjude.cloud',
    title: 'Pediatric Cancer Portal (PeCan)',
  },
  {
    url: 'https://viz.stjude.cloud',
    title: 'Visualization Community',
  },
];

const links = [
  {
    url: 'https://www.stjude.org/legal/u-s-privacy-policy-statement.html',
    text: 'Privacy',
    title: 'U.S. Privacy Policy Statement',
  },
  {
    url: 'https://www.stjude.org/legal.html',
    text: 'Disclaimer / Registrations / Copyright',
    title: 'Disclaimer / Registrations / Copyright Statement',
  },
  {
    url: 'https://www.stjude.org/legal/linking-policy.html',
    text: 'Linking',
    title: 'Linking Policy',
  },
  {
    url: 'https://www.stjude.org/legal/notice-of-privacy-practices.html',
    text: 'Privacy (HIPAA)',
    title: 'Notice of Privacy Practices (HIPAA)',
  },
  {
    url: 'https://www.stjude.org/legal/notice-of-non-discrimination.html',
    text: 'Non-Discrimination',
    title: 'Notice of Non-Discrimination',
  },
];
</script>
